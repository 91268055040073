import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Select from '../views/Select'
import Solarisation from '../views/Solarisation'
import DoubleExposure from '../views/DoubleExposure'
import Photocollage from '../views/Photocollage'
import Expl from '../views/Expl'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/select',
    name: 'Select',
    component: Select
  },
  {
    path: '/solarisation',
    name: 'solarisation',
    component: Solarisation
  },
  {
    path: '/surimpression',
    name: 'surimpression',
    component: DoubleExposure
  },
  {
    path: '/photocollage',
    name: 'photocollage',
    component: Photocollage
  },
  {
    path: '/explication/:id',
    name: 'Expl',
    component: Expl,
    props: route => ({ effect: route.params.id })
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
