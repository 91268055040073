import gsap, {
  TweenMax,
  Power2
} from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from './_libs/greensock/InertiaPlugin'
// import Raf from '../Raf'
// import Bowser from 'bowser'
gsap.registerPlugin(Draggable, InertiaPlugin)

export default class Slide {
  constructor (el, endFunction) {
    this.el = el
    this.endFunction = endFunction

    if (!endFunction) {
      console.error('No endFunction')
      return;
    }

    this.damp = 0.05
    this.target = [0, 0]
    this.transform = [0, 0]
    this.slider = this.el.querySelector('.slider-el')
    this.items = Array.from(this.slider.childNodes)

    this.initValue()
    this.initSlider()
    this.initEventItems()

    this.endFunction.call(null, this.sliderIndex)
  }

  initValue () {
    this.lastSnap = 0
    this.currentIndex = 0

    this.speed = 0.7
    this.slidePerView = 1
    this.activeIndex = 0
    this.percent = -1
    this.bulletClick = false
    this.sliderIndex = 0
  }

  initSlider () {
    const firstItem = this.items[0]
    const marginRight =  window.getComputedStyle(firstItem, null).getPropertyValue("margin-right")
    this.width = 40  + parseInt(marginRight)

    this.drag = Draggable.create(this.slider, {
      type: 'x',
      // allowEventDefault: true,
      trigger: this.el,
      dragClickables: true,
      edgeResistance: 0.9,
      bounds: this.el,
      inertia: true,
      zIndexBoost: false,
      zIndex: 0,
      dragResistance: 0,
      onThrowUpdate: () => {
        this.dragUpdate()
      },
      onDrag: () => {
        this.dragUpdate()
      },
      onThrowComplete: () => {
        this.endFunction.call(null, this.sliderIndex)
      },
      snap: {
        x: (endValue) => {
          return Math.round(endValue / this.width) * this.width
        }
      }
    })

    this.drag.zIndex = 0
    this.dragUpdate()
  }

  initEventItems () {
    this.items.forEach((item, i) => {
      item.addEventListener('click', () => {
        this.goTo(i)
      })
    })
  }

  dragUpdate () {
    this.sliderIndex = Math.abs(Math.floor(this.drag[0].x / (this.width) - 0.5)) - 1

    this.percent = Math.abs((this.drag[0].x) / (this.width)) - this.activeIndex

    this.items.map((el, i) => {
      el.percent = Math.abs(((this.drag[0].x)) / (this.width)) - i
    })
  }

  goTo (id, time) {
    let timing
    if (time) {
      timing = time
    } else {
      timing = Math.abs(id - this.sliderIndex)
    }

    this.goToTween = TweenMax.to(this.slider, this.speed * timing, {
      x: this.el.dataset.infinite ? '+=' + id * this.width : -id * this.width,
      ease: Power2.easeInOut,
      onUpdate: () => {
        this.drag[0].update()
        this.dragUpdate()
      },
      onComplete: () => {
        this.endFunction.call(null, this.sliderIndex)
      }
    })
    
  }
}