class CameraManager {
  constructor() {
    this.video = document.createElement('video')
    this.video.classList.add('video-texture')
    this.video.setAttribute('playsinline', true);
    this.video.setAttribute('autoplay', true);

    this.canvas = document.createElement('canvas')
    this.canvas.classList.add('snapshot-canvas')

    document.documentElement.appendChild(this.video)
    document.documentElement.appendChild(this.canvas)
  }
  setVideo(cam, ctx) {
    this.constraints =  {
      video: {
        // width: 1920 * 2,
        // height: 1080 * 2,
        deviceId: {
          exact: window.selectedCamera
        },
        facingMode: cam
      },

    };
    this.ctx = ctx
    this.orientation = cam

    this.orientation = cam
    if(this.ctx.flipPlane) this.ctx.flipPlane(this.orientation)
    
    // console.log(cam)
    if(this.stream) {
  
      this.stream.getTracks().forEach(function(track) {
        if (track.readyState == 'live' && track.kind === 'video') {
            track.stop();
        }
      })
    }

    this.launchVid()
  }
  launchVid() {
    navigator.mediaDevices.getUserMedia(this.constraints)
      .then((stream) => {
        
        let videoTracks = stream.getVideoTracks();
        stream.onremovetrack = function () {
        };
        window.stream = stream; // rendre la variable disponible dans la console du navigateur

        this.stream = stream

        this.video.srcObject = stream

        setTimeout(() => {
          // console.log(this.video.getBoundingClientRect().width/this.video.getBoundingClientRect().height)
          this.ratio = this.video.getBoundingClientRect().width/this.video.getBoundingClientRect().height
          // if(this.ratio > 1) this.ratio = 1/this.ratio
          // console.log(this.video.getBoundingClientRect().width, this.video.getBoundingClientRect().height)
          document.querySelector("body").style.setProperty("--video-ratio", 0.75);
          this.ctx.ratio = 0.75
          // console.log('COUCOUCOU', this.ctx.ratio)
          window.addEventListener('resize', () => {
            // this.ratio = this.video.getBoundingClientRect().width/this.video.getBoundingClientRect().height
            // document.querySelector("body").style.setProperty("--video-ratio", this.video.getBoundingClientRect().width/this.video.getBoundingClientRect().height);
            // this.ctx.ratio = this.video.getBoundingClientRect().width/this.video.getBoundingClientRect().height
          })
        }, 200)

        this.video.play();
        this.ctx.video = this.video 
        if(!this.ctx.hasStream) this.ctx.onVideoLoad()

        this.ctx.hasStream = true
        

      })
      .catch((error) => {
        if (error.name === 'ConstraintNotSatisfiedError') {
          errorMsg('The resolution ' + this.constraints.video.width.exact + 'x' +
            this.constraints.video.height.exact + ' px is not supported by your device.');
        } else if (error.name === 'PermissionDeniedError') {
          errorMsg('Permissions have not been granted to use your camera and ' +
            'microphone, you need to allow the page access to your devices in ' +
            'order for the demo to work.');
        }
        errorMsg('getUserMedia error: ' + error.name, error);
      });

    function errorMsg(msg, error) {
      //   errorElement.innerHTML += '<p>' + msg + '</p>';
      if (typeof error !== 'undefined') {
        console.error(error);
      }
    }
  }

  takeSnapshot() {
    if(this.ctx.isNewPlane){
      this.ctx.updateImg()
    } else {
      this.ctx.setBackAction()

      this.img = document.createElement('img')
      this.img.classList.add('snapshot-img')
      this.ctx.$el.appendChild(this.img)
      this.canvas.height = window.innerHeight * 2;
      this.canvas.width =  window.innerHeight * 2 * this.ratio;
      this.canvas.getContext('2d').mozImageSmoothingEnabled = false;
      this.canvas.getContext('2d').webkitImageSmoothingEnabled = false;
      this.canvas.getContext('2d').msImageSmoothingEnabled = false;
      this.canvas.getContext('2d').imageSmoothingEnabled = false;
      this.canvas.getContext('2d').drawImage(this.video, 0, 0,  window.innerHeight * 2 * this.ratio, window.innerHeight * 2);
      var data = this.canvas.toDataURL('image/png');
      this.img.setAttribute('src', data);
      this.ctx.imgs.push(this.img)
      this.ctx.updateImg(this.img)
    }
   
  }

  importPhoto(url) {
    this.img = document.querySelector('.snapshot-img')
    // this.img.classList.add('snapshot-img')
    this.canvas.height = window.innerHeight;
    this.canvas.width = window.innerWidth;
    let img = new Image()
    img.src = this.img.src
    img.onload = () => {
      // this.canvas.getContext('2d').drawImage(this.img, 0, 0, window.innerWidth, window.innerHeight);
      let w = window.innerWidth 
      let h = window.innerWidth * img.naturalHeight/img.naturalWidth
      // this.canvas.getContext('2d').save()
      this.canvas.getContext('2d').scale(-1,1)
      this.canvas.getContext('2d').drawImage(this.img,(-window.innerWidth - w)* 0.5 , (window.innerHeight - h) * 0.5, w, h);
      // this.canvas.getContext('2d').restore()
      var data = this.canvas.toDataURL('image/png');
      // this.img.setAttribute('src', data);
      this.ctx.imgs.push(this.img)
      
      if(this.ctx.planes){
        console.log('COUCOU')
        this.ctx.planes[this.ctx.imgs.length - 1].orientation = -1
        this.ctx.planes[this.ctx.imgs.length - 1].scale.set(this.ctx.planes[this.ctx.imgs.length - 1].scaleVal * this.ctx.planes[this.ctx.imgs.length - 1].orientation, this.ctx.planes[this.ctx.imgs.length - 1].scaleVal, 1)
      } else {
        this.ctx.flipPlane('environement')
      }
      this.ctx.updateImg()
      if(this.ctx.$el.classList.contains('photocollage') && !this.ctx.isNewPlane){
        this.ctx.updateImg()
      }
    }
  }

}

let out = new CameraManager()
export default out
