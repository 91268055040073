/* eslint-disable */
// import component from '@/mixins/component'
const images = require.context('@/assets/images', false, /\.png$|\.jpg$/)
export default {
  name: 'Images',
  // mixins: [component],
  props: {
    data: {
      type: Object,
      default: ''
    },
  },
  methods: {
    loadImg(path) {
      return images('./' + path)
    }
  }
}
