

import Expl from '@/views/Expl'
export default {
  name: 'Title',
  components: {
    Expl
  },
  props: {
    data: {
    },
  },
  data () {
    return {
      explShown: false
    }
  },
  // created () {
  //   console.log('TEST', this.$parent.$route.name)
  // },
  mounted () {
  
  },
  methods: {
    toggleInfos () {
      this.explShown = !this.explShown
    }
  },
}
