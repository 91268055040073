<template>
  <div id="app" :class="{isdesktop : isDesktop }">
    <!-- <div id="nav">
      <router-link to="/solarisation">Solarisation</router-link> |
      <router-link to="/double-exposition">Double exposition</router-link> |
      <router-link to="/sollage">Collage</router-link>
    </div> -->
    <RotateScreen />
    <DesktopScreen />
    <router-view />
  </div>
</template>

<script>
import VueMeta from 'vue-meta'
import RotateScreen from './components/RotateScreen'
import DesktopScreen from './components/DesktopScreen'
import Bowser from 'bowser';

export default {
  name: "App",
  components: {
    RotateScreen,
    DesktopScreen
  },
  data() {
    return {
       metaInfo: {
        meta: [
          { charset: 'utf-8' },
          { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' }
        ]
      },
      isDesktop: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initResize();
      this.resize();
    });
    this.isDesktop = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type === 'desktop'

    window.addEventListener("resize", this.resize);
  },
  methods: {
    initResize() {
      const body = document.querySelector("body");

      body.style.setProperty("--vh", window.innerHeight / 100 + "px");
    },
    resize() {
      const body = document.querySelector("body");

      body.style.setProperty("--vh-rez", window.innerHeight / 100 + "px");
    },
  },
};
</script>

<style lang="less" src="./less/master.less"></style>

<style lang="less">
@import "@/less/_import.less";

body {
  touch-action: pan-y;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: #2c3e50;

  font-size: 18px;

  // width: 100vw;
  // height: 100vh;
  margin: 0;
  padding: 0;
  // overflow: hidden;

  &.isdesktop{
    height: 100vh;
    overflow: hidden;
  }
}

.video-texture {
  opacity: 0;
  position: absolute;
  /* display: no
  ne; */
  // height: 100vh;
  // width: calc(100vh * var(--video-ratio));
  top: 0;
  pointer-events: none;
  transform: scale(0.1);

  
}

.render-canvas {
  transition: 0.5s @ease-custom-out;
  z-index: -1;
  transition: transform 0.5s @custom-ease, opacity 0.5s @custom-ease 0.4s;
  position: fixed;
  transform: scale3D(1, 1, 1) translate3d(0, 0, 0);
  top: 0;
  left: 50%;

  transform: scale3D(1, 1, 1) translate3d(-50%, 0, 0) rotateY(180deg);
  height: 100vh !important;
  width: calc(100vh * var(--video-ratio)) !important;
  &.finished {
    transform:  translate3d(-50%, var(--canvas-translatey, -26vh), 0) rotateY(180deg) scale(var(--canvas-scale, 0.7)) ;
    opacity: 0;
  }
}

#nav {
  display: none;
}
.effect {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;

  &.isClosing{
     .info-btn{
       opacity: 0;
     }
  }

  &:before {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 80px;
    top: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  &:after {
    content: "";
    position: absolute;
    pointer-events: none;
    width: 100%;
    height: 100px;
    top: calc(var(--vh-rez) * 100);
    transform: translate3d(0, -100%, 0);
    left: 0;
    z-index: 2;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    opacity: 1;
    transition: opacity 0.5s ease;
  }

  h1 {
    font-size: 21px;
    color: @white;
    text-transform: uppercase;
    z-index: 10;
    font-weight: @medium;
    text-align: center;
    display: block;
    opacity: 1;
    transition: all 0.5s ease;
  }

  &.finished {
    &:before,
    &:after,
    h1 {
      opacity: 1;
      background: none;
    }
  }

  .icon {
    -webkit-filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.5));
    -moz-filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.5));
    -ms-filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.5));
    -o-filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.5));
    filter: drop-shadow(0px 0px 6px rgba(0,0,0,0.5));
  }
}
canvas {
  // width: 100% !important;
  // height: 100% !important;

}

.snapshot-img,
.snapshot-canvas {
  top: 0px;
  z-index: -100;
  max-height: 100vh;
  max-width: 100vw;
  position: absolute;
  opacity: 0;
  display: block;
}

#nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
