<template>
  <div class="camera-controls">
    <div >
      <div v-if="canBackAction" class="backAction" @click="backAction">
        <span class="icon icon-back"></span>
      </div>
      <img  class='snapshot-img' :src="image" alt="">
      <button class="close" @click="toggleClose">
        <span class="icon icon-close"></span>
      </button>
      <ul>
        <li v-if="showUploadSwitch" class="upload">
          <span class="icon icon-import"
            ><input
              ref="fileInput"
              @change="importFile"
              accept="image/png, image/jpeg"
              type="file"
          /></span>
        </li>
        <li
          @click="takeSnapshot"
          class="snapshot"
          v-html="$attrs.message"
        ></li>
        <li
          @click="finish"
          class="finish-btn"
          :class="showFinishBtn ? '-show' : ''"
          v-if="$attrs.origin === 'photocollage'"
        >
          OK
        </li>
        <li v-if="showUploadSwitch" @click="switchCamera" class="switch-camera">
          <span class="icon icon-switch"></span>
        </li>
      </ul>
    </div>
    <div class="give-up" :class="{ show: isClosing }">
      <button class="close" @click="toggleClose">
        <span class="icon icon-close"></span>
      </button>
      <p>{{ $t('constant.giveUp') }}</p>
      <div class="answer">
        <button @click="toggleClose">{{ $t('constant.no') }}</button>
        <button @click="close">{{ $t('constant.yes') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import "./style.less";
import CameraManager from "@/js/CameraManager";

export default {
  name: "CameraControls",
  data() {
    return {
      orientation: "user",
      torch: false,
      isEnd: false,
      isClosing: false,
      image: '',
      
    };
  },
  props: {
    showUploadSwitch: {
      type: Boolean,
      default: true
    },
    showFinishBtn: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.cameraObj = CameraManager;
    // console.log('TEST', this.$parent)
    this.cameraObj.setVideo(this.orientation, this.$parent);
  },
  computed: {
    canBackAction () {
      return this.$parent.canBackAction
    }
  },
  methods: {
    takeSnapshot() {
      if (!this.isEnd) {
        this.cameraObj.takeSnapshot();
      } else {
        // console.log("finish");
        this.$parent.finish();
      }
    },
    switchCamera() {
      // console.log('SWITCH', this.orientation)
      this.torch = false;
      this.orientation === "user"
        ? (this.orientation = "environment")
        : (this.orientation = "user");
      this.cameraObj.setVideo(this.orientation, this.$parent);
    },
    backAction() {
      this.$parent.backAction()
    },
    importFile(e) {
     let url = URL.createObjectURL(this.$refs.fileInput.files[0])

     const data = URL.createObjectURL(e.target.files[0]);
      this.image = data
      if (
        FileReader &&
        this.$refs.fileInput.files &&
        this.$refs.fileInput.files.length
      ) {
        var fr = new FileReader();
        fr.onload = () => {
          this.cameraObj.importPhoto(fr.result);
        };
        fr.readAsDataURL(this.$refs.fileInput.files[0]);
      }
    },
    finish() {
      this.$parent.finish();
    },
    toggleClose() {
      this.isClosing = !this.isClosing;
      if (this.isClosing) {
        this.$parent.$el.classList.add("isClosing");
      } else {
        this.$parent.$el.classList.remove("isClosing");
      }
    },
    close() {
      window.history.length > 2 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>
