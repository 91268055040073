import gsap from 'gsap'

export default {
  name: 'PopTexts',
  props: {
    data: {
      type: Array,
      default () {
        return []
      }
    },
    effet: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      showed: []
    }
  },
  mounted () {
    this.data.forEach(() => {
      this.showed.push(false)
    })
  },
  methods: {
    // si on veut récupérer si un text a été vu
    getShowed (index) {
      return this.showed[index]
    },
    hideText() {
      this.$refs.texts.forEach((text) => {
        text.classList.add('-hide')
      })
    },
    // Si on veut animer un text
    showText (index, duration = 4.2) {
      this.$refs.texts.forEach((text) => {
        text.classList.add('-hide')
      })
      let delay = 0
      if(this.animation) {
        this.animation.kill()
        this.animation = undefined
        delay = 0.3
      }

      const el = this.$refs.texts[index]
      this.animation = gsap.timeline()

      this.animation.to(el,
        {
          delay,
          duration,
          ease: 'Power4.out',
          onStart: () => {
            el.classList.remove('-hide')
          },
          onComplete: () => {
            this.showed[index] = true
            el.classList.add('-hide')
          }
        }
      )
    },
    // Si on veut animer un text après l'autre
    showTwoText (index, duration, indexBis) {
      this.showText(index, duration)
  
      const el = this.$refs.texts[indexBis]
  
      this.animation.to(el,
        {
          duration,
          ease: 'Power4.out',
          onStart: () => {
            el.classList.remove('-hide')
          },
          onComplete: () => {
            this.showed[indexBis] = true
            el.classList.add('-hide')
          }
        }
      )
    }
  },
}

