import Images from '@/components/Images'
import TextBlock from '@/components/TextBlock'

export default {
  name: 'blocks',
  components: {
    Images,
    TextBlock
  },
  props: {
    data: {
      type: Array,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  data () {
    const newData = []

    this.$props.data.forEach(nb => {
      const data = this.$t(`${this.$props.link}.${nb}`)
      newData.push(data)
    });

    return {
      newData
    }
  },
  created () {
  },
  mounted () {
  
  },
  methods: {
    
  }
}
