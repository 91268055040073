import Bowser from 'bowser';

export default {
  name: 'RotateScreen',
  mounted () {
    this.$nextTick(this.init.bind(this))
  },
  data () {
    return {
      isMobile: false,
      isHorizontal: false
    }
  },
  methods: {
    init () {
      this.isMobile = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type !== 'desktop'
    
      this.resize()
      window.addEventListener('resize', this.resize.bind(this))
    },
    resize () {
      this.isHorizontal = window.innerHeight < window.innerWidth
    }
  }
}