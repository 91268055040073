<template>
  <div class="endScreen">
    <div class="input-container">
      <input
        type="checkbox"
        ref="logoCheck"
        checked
        @change="toggleLogo"
        id="checkbox"
      />
      <div class="checkbox-style"></div>
      <label class="checkbox" for="checkbox">Logo</label>
    </div>

    <div class="options">
      <div class="download" ref="download" @click="download">
        <div class="loadingspinner"></div>
        <span class="icon icon-download"></span
        ><span>{{ $t("constant.download") }}</span>
      </div>
      <!-- <div class="share" @click="share">
        <span class="icon icon-share"></span>{{ $t('constant.share') }}
      </div> -->
    </div>

    <div class="restart-btn cta-main" @click="restart">
      {{ $t("constant.tryAgain") }}
    </div>

    <!-- Close -->
    <button class="close" @click="toggleClose">
      <span class="icon icon-close"></span>
    </button>

    <div class="give-up" :class="{ show: isClosing }">
      <button class="close" @click="toggleClose">
        <span class="icon icon-close"></span>
      </button>
      <p>{{ $t("constant.giveUp") }}</p>
      <div class="answer">
        <button @click="toggleClose">{{ $t("constant.no") }}</button>
        <button @click="close">{{ $t("constant.yes") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import "./style.less"
import CameraManager from "@/js/CameraManager"
import canvasLogo from "@/assets/images/logoJdp_shadow.png"
import gsap from "gsap"
import Raf from "@/js/raf"
import Bowser from "bowser"
// import Instagram from "instagram-web-api";
// import Facebook from "@/js/facebook";
const { username, password } = process.env

export default {
  name: "EndScren",
  data() {
    return {
      orientation: "user",
      torch: false,
      isEnd: false,
      alpha: 1,
      isClosing: false,
      size: {}
    }
  },
  props: {},
  mounted() {
    this.isNotVersion15 = Bowser.getParser(window.navigator.userAgent).parsedResult.os.version !== "15.0" && Bowser.getParser(window.navigator.userAgent).parsedResult.os.name === "iOS"
    console.log('VERSION', this.isNotVersion15)
    this.isSafari =
      Bowser.getParser(window.navigator.userAgent).parsedResult.os.name ===
      "iOS"
    // console.log(Bowser.getParser(window.navigator.userAgent).parsedResult)
    // console.log(this.isSafari)
    this.cameraObj = CameraManager
    this.cameraObj.setVideo(this.orientation, this.$parent)

    this.logoImg = new Image()
    this.logoImg.src = canvasLogo
    this.showLogo = true

    // console.log('ROIUTE =>', this.$route)
  },
  destroyed() {
    Raf.unsuscribe("endScreen")
  },
  methods: {
    setFinal() {
      this.setSizeCanvas()
      let canvas = document.querySelector(".render-canvas")
      // this.size.width = window.innerWidth;
      this.size.ratio = getComputedStyle(
        document.querySelector("body")
      ).getPropertyValue("--video-ratio")
      // this.size.height = this.size.width / this.size.ratio;

      console.log(canvas.width, canvas.height)

      this.finalCanvas = document.createElement("canvas")
      // this.finalCanvas.height =  this.size.height * 2;
      // this.finalCanvas.width = this.size.width * 2;
      this.finalCanvas.classList.add("final-canvas")
      // document.querySelector('.effect').appendChild(this.finalCanvas);

      this.$parent.$el.appendChild(this.finalCanvas)
      let imgSrc = canvas.toDataURL("image/png")
      this.img = new Image()

      this.img.src = imgSrc
      this.img.onload = () => {
        this.finalCanvas.height = this.img.naturalHeight
        this.finalCanvas.width = this.img.naturalWidth
        // console.log(this.finalCanvas)
        console.log(this.img)
        this.ctx = this.finalCanvas.getContext("2d")

        // console.log(this.finalCanvas)

        this.ctx.save()
        if (this.isSafari) this.ctx.scale(1, 1)
        if(this.isNotVersion15) this.ctx.scale(1,-1)
        this.ctx.drawImage(
          this.img,
          0,
          0,
          this.img.naturalWidth * -1,
          this.img.naturalHeight * -1
        )
        this.ctx.restore()

        Raf.suscribe("endScreen", () => {
          this.update()
        })
      }

      gsap.fromTo(this.finalCanvas, { opacity: 0 }, { opacity: 1, delay: 1 })
      // this.ctx = this.finalCanvas.getContext("2d");
      // this.ctx.scale(1,-1)
    },
    setSizeCanvas() {
      // console.log(this.$el, this)
      const heightEdnScreen = this.$el.getBoundingClientRect().height
      const height =
        window.innerHeight - heightEdnScreen - window.innerHeight * 0.3
      const scale = height / window.innerHeight
      const translation = (window.innerHeight - height) / -2 / scale + 100

      // console.log(translation, window.innerHeight, -height)

      this.$parent.$el.style.setProperty("--canvas-scale", scale)
      this.$parent.$el.style.setProperty("--canvas-translatey", -16 + "vh")
    },
    toggleLogo() {
      this.showLogo = this.$refs.logoCheck.checked
    },
    update() {
      // this.ctx.restore()
      // let width = window.innerWidth * 2 * 0.5;
      this.ctx.globalAlpha = 1
      this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight)

      // Flip image on mobile because who knows why !
      this.ctx.save()
      this.isNotVersion15 ? this.ctx.scale(-1, -1) : this.ctx.scale(-1, 1)
      // if(this.isNotVersion15) this.ctx.scale(-1,-1)
      this.ctx.mozImageSmoothingEnabled = false
      this.ctx.webkitImageSmoothingEnabled = false
      this.ctx.msImageSmoothingEnabled = false
      this.ctx.imageSmoothingEnabled = false

      // console.log(window.innerWidth * -2, this.isSafari ? window.innerHeight * -2 * this.size.ratio : window.innerHeight * 2 * this.size.ratio)
      // this.ctx.drawImage(this.img, 0, 0, window.innerWidth * -2, this.isSafari ? window.innerHeight * -2 * this.size.ratio : window.innerHeight * 2 * this.size.ratio);
      // console.log(0, 0, this.img.naturalWidth, this.img.naturalHeight)
      this.ctx.drawImage(
        this.img,
        0,
        0,
        this.img.naturalWidth * -1,
        // this.img.naturalHeight
        // this.isNotVersion15 ? this.img.naturalHeight * -1 : this.img.naturalHeight
        this.isNotVersion15 ? this.img.naturalHeight * -1 : this.img.naturalHeight
      )
      this.ctx.restore()

      this.ctx.globalAlpha = this.alpha

      if (this.showLogo) {
        this.alpha += 0.03
        if (this.alpha > 1) this.alpha = 1
        // this.alpha = Math.max(0, Math.min(this.alpha + 1, 0, 1, 1));
      } else {
        this.alpha *= 0.85
      }

      this.ctx.drawImage(
        this.logoImg,
        this.img.naturalWidth / 2,
        10,
        this.img.naturalWidth / 2,
        this.img.naturalWidth /
          2 /
          (this.logoImg.naturalHeight / this.logoImg.naturalWidth)
      )
    },
    download() {
      this.$refs.download.classList.add('-load')
      this.image = this.fixImage();

      const linkSource = this.image;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = "Jeu de Paume X Moma";
        downloadLink.click();

      setTimeout(() => {
        this.$refs.download.classList.remove('-load')
      }, 1000)
  

      // const linkSource = this.image;
      //   const downloadLink = document.createElement("a");
      //   downloadLink.href = linkSource;
      //   downloadLink.download = "Jeu de Paume X Moma";
      //   downloadLink.click();

      // setTimeout(() => {
      //   this.$refs.download.classList.remove('-load')
      // }, 1000)
    },
    async share() {
      if (navigator.share) {
        const imageUrl = this.fixImage()
        const image = await fetch(imageUrl)
        const blob = await image.blob()
        console.log(blob)
        var files = [
          new File([blob], "Jeu de Paume X Moma.jpg", {
            type: "image/jpeg",
            lastModified: new Date().getTime()
          })
        ]
        console.log(files)
        // const shareData = {
        //   // title: this.$t("share.title"),
        //   // url: window.location.href,
        //   // text: this.$t("share.text"),
        //   image: imageUrl
        // }
        navigator.share({image: imageUrl, mimeType: 'image/png'})
        // navigator
        //   .share(shareData)
        //   .then(() => {
        //     console.log("OK")
        //   })
        //   .catch("Err", console.error)
      } else {
        alert("Oups, votre mobile ne permet pas de partager du contenu.")
      }
    },
    restart() {
      const parent = this.$parent
      parent.reload()

      this.finalCanvas.remove()
    },

    fixImage() {
      let imgSrc = this.finalCanvas.toDataURL("image/png")
      document.querySelector(".snapshot-img").src = imgSrc
      return imgSrc

      let img = new Image()
      img.src = imgSrc
    },
    downloadBase64File(base64, fileName) {},
    toggleClose() {
      this.isClosing = !this.isClosing
    },
    close() {
      window.history.length > 2 ? this.$router.go(-1) : this.$router.push("/")
    }
  }
}
</script>
