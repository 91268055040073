import {Renderer, Geometry, Plane, Texture, Program, Mesh, Vec2, Color, Camera, Transform} from 'ogl';
import * as dat from 'dat.gui';

import { hslToHex } from '@/js/utils'

import vertex from 'raw-loader!glslify-loader!../../glsl/solarisation/solarisation.vert'
import fragment from 'raw-loader!glslify-loader!../../glsl/solarisation/solarisation.frag'


import SlideSelector from '@/components/SlideSelector'
import PopTexts from '@/components/PopTexts'
import Range from '@/components/Range'
import CameraControls from '@/components/CameraControls'
import Title from '@/components/Title'
import EndScreen from '@/components/EndScreen'

import Raf from '@/js/raf'
import CameraManager from '@/js/CameraManager'

export default {
  name: 'Solarisation',
  components: {
    SlideSelector,
    PopTexts,
    Range,
    CameraControls,
    EndScreen,
    Title
  },
  data () {
    return {
      params: {
        intensityEffect: 0,
        color: {
          h: 0,
          s: 90,
          l: 65
        },
        colored: false,
      },
      cameraMessage: '',
      isFinished: false,
      isPlaying: true,
      hasStream: false,
      takeScreen: 0,
      imgs: [],
      canBackAction: false,
      ratio: 0.75
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    this.$root.$el.style.overflow = ''
    Raf.unsuscribe('solarisation')
  },
  methods: {
    init () {
      this.ranges = [
        this.$refs.range1,
        this.$refs.range2
      ]
  
      this.cameraObj = CameraManager
      this.cameraObj.setVideo('user', this)
  
      this.$root.$el.style.overflow = 'hidden'
  
      this.$nextTick(() => {
        this.$refs.selector.initSlide()
        this.initCanvas()
      })
    },

    //
    // OGL
    //
    initCanvas () {
      this.createRenderer()
      this.createImage()

      // this.initDatGUI()
      this.initEvents()

      Raf.suscribe('solarisation', () =>{this.update()})
    },
    createRenderer () {
      this.renderer = new Renderer({
        dpr: 2,
        width:  window.innerHeight * this.ratio,
        height: window.innerHeight,
        preserveDrawingBuffer: true,
        alpha: true,
        antialias: true
      });

      // this.$el.style.width = window.innerWidth + 'px'
      // this.$el.style.height = window.innerHeight + 'px'

      this.gl = this.renderer.gl;
      this.gl.canvas.classList.add('render-canvas')

      this.$refs.canvas.appendChild(this.gl.canvas);
      this.scene = new Transform();
      this.camera = new Camera(this.gl, {
        fov: 15
      });
      this.camera.position.z = 3.5;
    },
    createImage () {
      // Triangle that covers viewport, with UVs that still span 0 > 1 across viewport
      let geometry = new Plane(this.gl, {
        position: {
          size: 2,
          data: new Float32Array([-1, -1, 3, -1, -1, 3])
        },
        uv: {
          size: 2,
          data: new Float32Array([0, 0, 2, 0, 0, 2])
        },
      });


      this.texture = new Texture(this.gl, {
        generateMipmaps: false
      });

  
      const program = new Program(this.gl, {
        vertex,
        fragment,
        uniforms: {
          tMap: { value: this.texture },
          u_mapResolution: {
            value: new Vec2(0, 0)
          },
          u_resolution: {
            value: new Vec2(window.innerWidth, window.innerHeight)
          },
          u_aspect: {
            value: new Vec2(1, 1)
          },
          u_translation: {
            value: new Vec2(0, 0)
          },
          u_intensityEffect : {
            value: this.params.intensityEffect
          },
          u_color: {
            value: new Color(0xffffff)
          },
          u_colorIntensity: {
            value: this.params.colored ? 1 : 0
          }
          ,
          u_flip: {
            value: 1
          }
        },
        cullFace: null
      });
     
      this.mesh = new Mesh(this.gl, {geometry, program});
      this.mesh.position.set(0, 0, 0)
      this.mesh.scaleVal = 1
      this.mesh.scale.set(1, 1, 1)
      this.mesh.scaleVal = 1
      this.mesh.setParent(this.scene);
    },
    initEvents () {
      window.addEventListener('resize', this.onResize)
    },
    onResize () {
      const w = window.innerHeight * 0.75
      const h = window.innerHeight

      this.renderer.setSize(w, h)
    },

    onVideoLoad() {
      this.mesh.program.uniforms.u_mapResolution.value = new Vec2(1080, 1920)
    },

    update () {
      if(this.hasStream && this.isPlaying) {
        if(this.imgs.length === 0) {
          this.texture.image = this.video
          this.texture.needsUpdate = true;
        }
      }
      this.renderer.render({scene: this.scene, camera: this.camera});
    },

    flipPlane(way) {
      if (!this.mesh) return
        this.mesh.program.uniforms.u_flip.value *= -1
    },
    updateImg() {
      if (this.isFinished) return
      console.log(this.mesh.program.uniforms.u_flip.value)
      this.isPlaying = false
      this.texture.image = this.imgs[this.imgs.length - 1] 
      this.texture.needsUpdate = true;

      this.takeScreen += 1
      if (this.takeScreen === 1) {
        this.cameraMessage = 'OK'
      } else {
        this.cameraMessage = ''
      }
      if (this.takeScreen >= 2) this.finish()
    },
    // update sahder
    initDatGUI () {
      this.debug = new dat.GUI()
      this.debug.close()
      
      this.debug.add(this.params, 'intensityEffect', 0, 1).onChange(this.updateIntensityEffect)
      
      this.debug.add(this.params.color, 'h', 0, 360).onChange(this.updateColor)
      this.debug.add(this.params.color, 's', 0, 100).onChange(this.updateColor)
      this.debug.add(this.params.color, 'l', 0, 100).onChange(this.updateColor)
      this.debug.add(this.params, 'colored').onChange((value) => {
        this.mesh.program.uniforms.u_colorIntensity.value = value ? 1 : 0
      })
    },
    updateColor (h) {
      this.mesh.program.uniforms.u_colorIntensity.value = 1

      if (h > 0) {
        this.params.color.h = h || this.params.color.h

        const color = hslToHex(
          this.params.color.h,
          this.params.color.s,
          this.params.color.l
        )
        this.mesh.program.uniforms.u_color.value = new Color(color)
      } else {
        const progress = (Math.max(-8, Math.min(h, 5)) + 5) / 5;
        const color = hslToHex(
          0,
          this.params.color.s * progress,
          this.params.color.l + 35 * Math.max(Math.min(1 - progress, 1), 0)
        )
        this.mesh.program.uniforms.u_color.value = new Color(color)
      }
      
    },
    updateIntensityEffect(value) {
      this.mesh.program.uniforms.u_intensityEffect.value = value
    },
    //
    //
    //
    selectedFiltre (valueArr) {
      const popText = this.$refs.PopTexts
      if (!popText.getShowed(valueArr[0]) && valueArr[1] === null) {
        popText.showTwoText(2, 4, valueArr[0])
      } else if (!popText.getShowed(valueArr[0])) {
        popText.showText(valueArr[0])
      }

      this.ranges[valueArr[0]].show()
      if (valueArr[1] !== null && valueArr[0] !== valueArr[1]) {
        this.ranges[valueArr[1]].hide()
      }
    },

    //
    // BackAction
    //
    setBackAction (action = true) {
      this.canBackAction = action
    },
    backAction () {
      this.isFinished = false
      this.cameraMessage = ''
      this.takeScreen = 0
      this.setBackAction(false)

      this.imgs.pop()
      this.isPlaying = true
      this.video.play()
      this.texture.image = this.video
    },

    //
    // Finish
    //
    finish () {
      this.isFinished = true
      this.$nextTick(() => {
        this.$refs.endScreen.setFinal()
      })
      Raf.unsuscribe('solarisation')
      this.$refs.PopTexts.hideText()
      this.isShown = false
      this.$el.querySelector('.render-canvas').classList.add('finished')
    },

    reload () {
      this.isFinished = false
      this.isShown = true
      this.canBackAction = false
      this.isPlaying = true
      this.hasStream = false
      this.takeScreen = 0
      this.$el.querySelector('.render-canvas').classList.remove('finished')
      this.imgs = []

      this.gl.canvas.remove()
      this.init()

      this.ranges[0].init()
      this.ranges[1].init()
    }
  }
}