import {
  Renderer,
  Geometry,
  Plane,
  Texture,
  Program,
  Mesh,
  Vec2,
  Vec3,
  Color,
  Transform,
  RenderTarget,
  Triangle,
  Camera
} from 'ogl';
import * as dat from 'dat.gui';
import {
  vec2, vec3
} from 'gl-matrix'


import CameraControls from '@/components/CameraControls'
import SlideSelector from '@/components/SlideSelector'
import PopTexts from '@/components/PopTexts'
import EndScreen from '@/components/EndScreen'

import Raf from '@/js/raf'
import Mouse from '@/js/mouse'

import Title from '@/components/Title'

import vertex from 'raw-loader!glslify-loader!../../glsl/photocollage/photocollage.vert'
import fragment from 'raw-loader!glslify-loader!../../glsl/photocollage/photocollage.frag'


const _VEC2 = vec2.create()

export default {
  name: 'photocollage',
  components: {
    CameraControls,
    SlideSelector,
    PopTexts,
    EndScreen,
    Title
  },
  data() {
    return {
      params: {},
      isPlaying: true,
      hasStream: false,
      imgs: [],
      planes: [],
      currentPlane: null,
      cameraMessage: 1,
      isFinished: false,
      isNewPlane: false,
      isShown: false,
      canBackAction: false,
      showFinishBtn: false,
      click: 0,
      ratio: 0.75
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initCanvas()
      this.$refs.selector.initSlide()
    })


  },

  destroyed() {
    Raf.unsuscribe('photocollage')
    Mouse.off('down')
    Mouse.off('drag')
    Mouse.off('pinch')
  },
  methods: {
    initCanvas() {

      this.createRenderer()
      this.createFirstPlane()

      this.initEvents()

      Raf.suscribe('photocollage', () => {
        this.update()
      })

      Mouse.on('down', () => {
        // if (this.planes.length-1  === null || !Mouse.currentEvent.target.classList.contains('render-canvas')) return
        
        this.mouseOrigin = vec2.fromValues(Mouse.cursor[0], Mouse.cursor[1])
        this.planeOrigin = vec2.fromValues(-this.planes[this.planes.length-1].position[0], -this.planes[this.planes.length-1].position[1])
      })
      Mouse.on('drag', () => {
        if (this.planes.length-1 === null || !Mouse.currentEvent.target.classList.contains('render-canvas')) return
        this.planeMove(this.planes.length-1)
      })
      Mouse.on('pinch', (way) => {
        // console.log(way)
        this.planeZoom(this.planes.length-1, way)
      })
    },
    createRenderer() {
      this.renderer = new Renderer({
        dpr: 2,
        width:  window.innerHeight * this.ratio,
        height: window.innerHeight,
        preserveDrawingBuffer: true,
        alpha: true
      });

      this.gl = this.renderer.gl;
      // this.renderer.preserveDrawingBuffer = true
      
      this.gl.canvas.classList.add('render-canvas')
      this.$el.appendChild(this.gl.canvas);
      this.scene = new Transform();
      this.camera = new Camera(this.gl, {
        fov: 15
      });
      this.camera.position.z = 3.5;


    },
    play() {
      this.video.play()
      this.isPlaying = true
      this.texture.image = this.imgs[0]
      this.texture.needsUpdate = true;

      this.onResize()

    },

    createFirstPlane() {
      // Triangle that covers viewport, with UVs that still span 0 > 1 across viewport
        let geometry = new Plane(this.gl, {
          position: {
            size: 2,
            data: new Float32Array([-1, -1, 3, -1, -1, 3])
          },
          uv: {
            size: 2,
            data: new Float32Array([0, 0, 2, 0, 0, 2])
          },
        });

        let texture = new Texture(this.gl, {
          generateMipmaps: false
        })

        let program = new Program(this.gl, {
          vertex,
          fragment,
          transparent: true,
          uniforms: {
            tMap1: {
              value: texture
            },

            u_mapResolution: {
              value: new Vec2(0, 0)
            },
            u_resolution: {
              value: new Vec2(window.innerWidth, window.innerHeight)
            },
            u_aspect: {
              value: new Vec2(1, 1)
            },
            u_shape: {
              value: 0
            }
          },
          cullFace: null
        });

        let mesh = new Mesh(this.gl, {
          geometry,
          program
        });
        mesh.program.uniforms.u_mapResolution.value = new Vec2(window.innerWidth, window.innerHeight)
        mesh.texture = texture
        mesh.position.set(0, 0, 0)
        mesh.zIndex = 0
        mesh.scale.set(1, 1, 1)
        mesh.scaleVal = 1
        mesh.orientation = 1
        mesh.setParent(this.scene)
        this.planes[0] = mesh
    },

    createPlane() {
      // Triangle that covers viewport, with UVs that still span 0 > 1 across viewport
        let geometry = new Plane(this.gl, {
          position: {
            size: 2,
            data: new Float32Array([-1, -1, 3, -1, -1, 3])
          },
          uv: {
            size: 2,
            data: new Float32Array([0, 0, 2, 0, 0, 2])
          },
        });

        let texture = new Texture(this.gl, {
          generateMipmaps: false
        })

        let program = new Program(this.gl, {
          vertex,
          fragment,
          transparent: true,
          uniforms: {
            tMap1: {
              value: texture
            },
            u_mapResolution: {
              value: new Vec2(0, 0)
            },
            u_resolution: {
              value: new Vec2(window.innerWidth, window.innerHeight)
            },
            u_aspect: {
              value: new Vec2(1, 1)
            },
            u_shape: {
              value: this.currentShape
            }
          },
          cullFace: null
        });

        let mesh = new Mesh(this.gl, {
          geometry,
          program
        });
        mesh.program.uniforms.u_mapResolution.value = new Vec2(window.innerWidth, window.innerHeight)
        mesh.texture = texture
        mesh.position.set(Math.random() * 0.5 - 0.25, Math.random() * 0.5 - 0.25, this.planes.length * 0.01)
        // mesh.position.set(0, 0, 0)
        mesh.scale.set(1, 1, 1)
        mesh.scaleVal = 1
        mesh.orientation = 1
        mesh.setParent(this.scene)
        mesh.zIndex = this.planes.length * 0.01
        this.planes[this.planes.length] = mesh
        this.isNewPlane = false

        // if planes add btn "OK"
        if(this.planes.length >= 2) {
          this.showFinishBtn = true
        }
    },
    

    initEvents() {
      window.addEventListener('resize', this.onResize)
    },

    onVideoLoad() {
      this.planes.forEach(el => {
        el.program.uniforms.u_mapResolution.value = new Vec2(1080, 1920)
      })
      this.$refs.PopTexts.showText(0)
      this.$refs.selector.$el.querySelectorAll('.selector__item')[0].classList.remove('hidden')
    },

    onResize() {
      const w = window.innerHeight * 0.75
      const h = window.innerHeight

      this.renderer.setSize(w, h)

      // Resolution
      // this.mesh.program.uniforms.u_resolution.value = new Vec2(w, h)
    },
    update() {
      if (this.hasStream && this.isPlaying) {
          if(!this.planes[this.planes.length - 1].isStopped) {
            this.planes[this.planes.length - 1].texture.image = this.video
            this.planes[this.planes.length - 1].texture.needsUpdate = true;
          }
      }

      this.renderer.render({
        scene: this.scene,
        camera: this.camera,
      });
    },

    updateImg() {
      if(!this.isShown) this.isShown = true
      if(this.isNewPlane) {
        this.cameraMessage = this.imgs.length + 1
        this.createPlane()
      } else {
        this.cameraMessage = '<span class="icon-plus"></span>'
        this.planes[this.planes.length - 1].texture.image = this.imgs[this.imgs.length - 1]
        this.planes[this.planes.length - 1].texture.image.needsUpdate = true;
        this.planes[this.planes.length - 1].isStopped = true
        this.isNewPlane = true
      }

      // ShowText
      this.click += 1
      console.log(this.click)
      if (this.click === 1) {
        this.$refs.PopTexts.showText(1)
      } else if (this.click === 2) {
        this.$refs.PopTexts.showText(2)
      } else if (this.click === 3) {
        this.$refs.PopTexts.showText(1)
      } else if (this.click === 4) {
        this.$refs.PopTexts.showText(2)
      }
     
    },

    selectedFiltre(valueArr) {
      this.currentShape = valueArr[0] + 1
      if(this.planes.length > 1) this.planes[this.planes.length - 1].program.uniforms.u_shape.value = this.currentShape
    },


    flipPlane(way) {
      setTimeout(() => {
        let plane = this.planes[this.imgs.length]
        this.planes.forEach((el, i) => {
          if(i >= this.imgs.length){
            way === "user" ? el.orientation = 1 : el.orientation = -1
            el.scale.set(el.scaleVal  * el.orientation, el.scaleVal, 1)
          }
        })
       
      }, 200)
      
    },

    planeMove(id) {
      if (id <= 0) return

      let translate = vec2.sub(_VEC2, this.mouseOrigin, Mouse.cursor)

      translate = vec2.sub(_VEC2, translate, this.planeOrigin)
      translate = vec2.scale(_VEC2, translate, 0.5)

      // this.planes[id].position.set(-translate[0] * (1 + 1/this.planeOrigin[2]), translate[1]* (1 + 1/this.planeOrigin[2]), this.planes[id].zIndex)
      this.planes[id].position.set(translate[0], translate[1], this.planes[id].zIndex)
    },

    planeZoom(id, way) {
      if (id <= 0) return
      if(way === 'out' && this.planes[id].scaleVal < 0.2 ) return
      way === 'in' ? this.planes[id].scaleVal += 0.05 : this.planes[id].scaleVal -= 0.05
      this.planes[id].scale.set(this.planes[id].scaleVal  * this.planes[id].orientation, this.planes[id].scaleVal, 1)
    },

    //
    // BackAction
    //
    setBackAction (action = true) {
      this.canBackAction = action
    },
    backAction () {
      console.log(this.planes.length)
      if (this.planes.length <= 1) {
        this.setBackAction(false)

        return
      }

      this.imgs.pop()

      // remove last plane
      const lastPlane = this.planes[this.planes.length -1]
      this.scene.removeChild(lastPlane)
      this.planes.pop()

      // last off last plane
      const secondLastPlane = this.planes[this.planes.length -1]
      secondLastPlane.texture.image = this.video
      secondLastPlane.texture.needsUpdate = true;
      secondLastPlane.isStopped = false

      this.cameraMessage = Math.max(this.planes.length, 1)
      this.isNewPlane = false

      if (this.planes.length <= 1) {
        this.setBackAction(false)
      }
    },

    //
    // Finish
    //
    finish() {

      this.isFinished = true
      this.$nextTick(() => {
        this.$refs.endScreen.setFinal()
      })
      Raf.unsuscribe('photocollage')
      this.$refs.PopTexts.hideText()
      this.isShown = false
      this.$el.querySelector('.render-canvas').classList.add('finished')
    },

    reload () {
      Raf.suscribe('photocollage', this.update)
      this.$refs.cameraControls.isEnd = false
      this.isPlaying = true
      this.cameraMessage = 1
      this.hasStream = true
      this.imgs = []
      this.isFinished = false 

      this.planes.forEach(el => {
        this.scene.removeChild(el)
      })
      this.planes = []
      // console.log(this.planes)
      this.createFirstPlane()
      this.planes[0].texture.image = this.video
      this.planes[0].isStopped = false
      this.$el.querySelector('.render-canvas').classList.remove('finished')
    }
  }
}