import gsap, {
  TweenMax,
  Power2,
  Linear
} from 'gsap'
import Draggable from 'gsap/Draggable'
import InertiaPlugin from './_libs/greensock/InertiaPlugin'
// import Raf from '../Raf'
import Bowser from 'bowser'
gsap.registerPlugin(Draggable, InertiaPlugin)

export default class SliderElement {
  constructor(el, type, target) {
    this.damp = 0.05
    this.target = [0, 0]
    this.transform = [0, 0]
    this.el = el
    this.targetAnim = target
    this.arrows = this.el.querySelector('.arrows')
    this.slider = this.el.querySelector('.slider-el')
    this.items = Array.from(this.el.querySelectorAll('.item, .variable-item'))
    this.bullets = this.el.querySelectorAll('.bullet-points li')
    this.isMobile = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type !== 'desktop'
    this.isDesktop = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type === 'desktop'
    this.type = type
    this.lastSnap = 0
    // console.log(this.items)
    this.items.forEach((el, i) => {
      let img = new Image()
      img.src = el.querySelector('img').src
      img.onload = () => {
        if (i === this.items.length - 1) this.init()
      }
    })
  }
  init() {
    // this.images = this.el.querySelectorAll('img')
    // this.texts = this.el.querySelectorAll('.text')
    this.speed = this.el.dataset.slidespeed || 1.8
    this.slidePerView = this.el.dataset.slideperview || 1
    this.activeIndex = 0
    this.percent = -1
    this.bulletClick = false
    this.sliderIndex = 0

    // Raf.suscribe('slider', this.update.bind(this))
    // console.log(this.type)
    if (this.el.dataset.infinite) {
      if (this.type === 'mobile') {
        if (this.isMobile) this.initInfiniteSlider()
      } else if (this.type === 'desktop') {
        if (this.isDesktop) this.initInfiniteSlider()
      } else {
        this.initInfiniteSlider()
      }
    } else {
      if (this.type === 'mobile') {
        if (this.isMobile) this.initSlider()
      } else if (this.type === 'desktop') {
        setTimeout(() => {
          if (this.isDesktop) {
            this.initSlider()
          }
        }, 1000)
      } else {
        this.initSlider()
      }
    }
  }

  initSlider() {
    // console.log('init')
    this.arrowsIndex = this.el.dataset.slidearrow || 1
    // console.log(this.el)
    if (this.arrows) {
      this.prev = this.el.querySelector('.arrow--prev, .arrow-prev')
      this.next = this.el.querySelector('.arrow--next, .arrow-next')
      this.prev.addEventListener('click', () => {
        if (this.el.dataset.infinite) {
          this.goTo(1)
        } else {
          if (this.sliderIndex - this.arrowsIndex < 0) {
            this.goTo(0)
          } else {
            this.goTo(this.sliderIndex - this.arrowsIndex)
          }
        }
      })
      this.next.addEventListener('click', () => {
        if (this.el.dataset.infinite) {
          this.goTo(-1)
        } else {
          if (this.sliderIndex + this.arrowsIndex > this.items.length - this.slidePerView) {
            this.goTo(this.items.length - this.arrowsIndex)
          } else {
            this.goTo(this.sliderIndex + this.arrowsIndex)
          }
        }
      })
    }


    this.width = this.items[0].getBoundingClientRect().width
    const boundingElement = this.el.getBoundingClientRect()
    const boundingSlider = this.slider.getBoundingClientRect()
    const snapOffset = boundingElement.left.toFixed()

    this.snaps = this.items.map((item, i) => {
      item.image = item.querySelector(`.${this.targetAnim}`)

      const boundings = item.getBoundingClientRect()
      return -(boundings.left.toFixed() - snapOffset)
    })
    // const lastValue = -boundingSlider.width + boundingElement.width
    this.snaps.push(-this.slider.getBoundingClientRect().width)
    this.snaps = this.snaps.sort(function (a, b) {
      return a - b
    })
    this.snaps = this.snaps.reverse()

    // this.canvas = new CanvasSlider(this.el, this.items)


    if (this.arrow && boundingSlider.width !== 0 && boundingSlider.width < this.el.getBoundingClientRect().width) {
      this.arrows.style.display = 'none'
    } else {

      this.drag = Draggable.create(this.slider, {
        type: 'x',
        // allowEventDefault: true,
        dragClickables: true,
        edgeResistance: 0.9,
        bounds: this.el,
        inertia: true,
        zIndexBoost: false,
        throwResistance: 0,
        zIndex: 0,
        maxDuration: 0.3,
        dragResistance: 0,
        onThrowUpdate: () => {
          this.dragUpdate()
        },
        onDrag: () => {
          this.dragUpdate()
        },
        snap: {
          x: this.snaps
          // x: (endValue) => {
          //   return Math.round(endValue / this.width) * this.width
          // }
        }
      })
      this.drag.zIndex = 0
      this.dragUpdate()
      if (this.el.dataset.starting) this.goTo(this.el.dataset.starting)
    }
  }

  initInfiniteSlider() {
    this.arrowsIndex = this.el.dataset.slidearrow || 1
    // console.log(this.el)
    if (this.arrows) {
      this.prev = this.el.querySelector('.arrow--prev, .arrow-prev')
      this.next = this.el.querySelector('.arrow--next, .arrow-next')
      this.prev.addEventListener('click', () => {
        if (this.el.dataset.infinite) {
          this.goTo(1)
        } else {
          if (this.sliderIndex - this.arrowsIndex < 0) {
            this.goTo(0)
          } else {
            this.goTo(this.sliderIndex - this.arrowsIndex)
          }
        }
      })
      this.next.addEventListener('click', () => {
        if (this.el.dataset.infinite) {
          this.goTo(-1)
        } else {
          if (this.sliderIndex + this.arrowsIndex > this.items.length - this.slidePerView) {
            this.goTo(this.items.length - this.arrowsIndex)
          } else {
            this.goTo(this.sliderIndex + this.arrowsIndex)
          }
        }
      })
    }


    this.mainSize = 0
    this.proxy = this.el.querySelector('.proxy')
    this.currentIndex = 0
    this.items.forEach((item, i) => {
      gsap.set(item, {
        x: this.mainSize
        // width: item.getBoundingClientRect().width
      })
      this.mainSize += item.getBoundingClientRect().width
    })

    this.snaps = this.items.map((item, i) => {
      const boundings = item.getBoundingClientRect()

      if (i === 0) {
        this.lastSnap -= (boundings.width * 0.5)
        return this.lastSnap
      } else if (i < this.items.length - 1) {
        this.lastSnap -= (boundings.width * 0.5 + this.items[i + 1].getBoundingClientRect().width * 0.5)
        return this.lastSnap
      } else {
        this.lastSnap -= boundings.width * 0.5 + this.items[0].getBoundingClientRect().width * 0.5
        return this.lastSnap
      }
    })

    if (!this.isMobile) {
      this.slider.style.transform = `translateX(${this.snaps[0] * 2 + window.innerWidth * 0.5 - this.mainSize * 0.5}px)`
    } else {
      this.slider.style.transform = `translateX(${this.snaps[0] - this.mainSize * 0.5}px)`
    }


    this.sliderOffset = window.innerWidth * 0.5
    this.snapsPrev = this.snaps.map(el => {
      return el + this.mainSize
    })

    this.snaps = this.snapsPrev.concat(this.snaps)

    let mainSize = this.mainSize
    this.animation = gsap.to(this.items, 1, {
      x: '+=' + this.mainSize,
      ease: Linear.easeNone,
      paused: true,
      repeat: -1,
      modifiers: {
        x: gsap.utils.unitize(function (x) {
          x = x % mainSize
          return x
        })
      }
    })

    let width = this.items[0].getBoundingClientRect().width
    this.width = width


    this.drag = Draggable.create(this.proxy, {
      type: 'x',
      trigger: this.el,
      throwProps: true,
      onDrag: () => {
        this.updateProgress()
      },
      onThrowUpdate: () => {
        this.updateProgress()
      },
      snap: {
        // x: (x) => Math.round(x / width) * width
        x: this.snaps
      }
    })
    this.goTo(0)
  }

  updateProgress() {
    let index = Math.floor(this.drag[0].x / this.mainSize)
    if (this.currentIndex !== index) {
      if (this.currentIndex > index) {
        this.snaps = this.snaps.slice(this.items.length)
        let snapsPlus = this.snaps.map(el => {
          return el - this.mainSize
        })
        this.snaps = this.snaps.concat(snapsPlus)
        this.drag[0].vars.snap.x = this.snaps
      } else {
        this.snaps = this.snaps.slice(0, this.items.length)
        let snapsMinus = this.snaps.map(el => {
          return el + this.mainSize
        })
        this.snaps = snapsMinus.concat(this.snaps)
        this.drag[0].vars.snap.x = this.snaps
      }
      this.currentIndex = index
    }
    // if((this.drag[0].x % this.mainSize <)
    this.animation.progress(1000 + (this.drag[0].x / this.mainSize))
    // this.animation.progress(this.drag[0].x / this.mainSize)
  }

  mouseMove(e) {
    this.mouse = [
      e.clientX - window.innerWidth / 2,
      e.clientY - window.innerHeight / 2
    ]

    this.target = this.mouse
  }

  dragUpdate() {
    this.sliderIndex = Math.abs(Math.floor(this.drag[0].x / (this.width) - 0.5)) - 1

    if (this.snaps) {
      this.snaps.map((el, i) => {
        if (this.drag[0].x - 10 < el) {
          this.sliderIndex = i
        }
      })
    } else {
      this.sliderIndex = Math.abs(Math.floor(this.drag[0].x / (this.width) - 0.5)) - 1
    }

    this.percent = Math.abs((this.drag[0].x) / (this.width)) - this.activeIndex
    if (this.bullets.length > 0) {
      this.bullets.forEach(el => {
        el.classList.remove('active')
      })
      this.bullets[this.sliderIndex].classList.add('active')
    }

    if (this.prev || this.next) {
      this.prev.classList.remove('disable')
      this.next.classList.remove('disable')
      if (this.drag[0].x <= this.drag[0].minX) {
        this.next.classList.add('disable')
      }
      if (this.drag[0].x >= 0 || this.drag[0].x === 0) {
        this.prev.classList.add('disable')
      }
    }

    this.items.map((el, i) => {
      el.percent = Math.abs(((this.drag[0].x)) / (this.width)) - i
      if (i === 1) {}
      if (this.isMobile) {
        if (el.image) {
          el.percent > 0 ? el.image.style.transformOrigin = '100% 50%' : el.image.style.transformOrigin = '0% 50%'
          el.image.style.transform = `scale(${1 - Math.abs(el.percent) * 0.1})`
        }
      }
    })
  }

  goTo(id, time) {
    let timing
    if (time) {
      timing = time
    } else {
      timing = Math.abs(id - this.sliderIndex)
    }
    let slider
    if (this.el.dataset.infinite) {
      slider = this.proxy
    } else {
      slider = this.slider
    }
    // console.log(this.snaps[id], id)
    if (this.snaps) {
      this.goToTween = TweenMax.to(slider, this.speed * timing, {
        x: this.snaps[id],
        ease: Power2.easeInOut,
        onUpdate: () => {
          this.drag[0].update()
          if (this.el.dataset.infinite) {
            this.updateProgress()
          } else {
            this.dragUpdate()
          }
        }
      })
    } else {
      this.goToTween = TweenMax.to(slider, this.speed * timing, {
        x: this.el.dataset.infinite ? '+=' + id * this.width : -id * this.width,
        ease: Power2.easeInOut,
        onUpdate: () => {
          this.drag[0].update()
          if (this.el.dataset.infinite) {
            this.updateProgress()
          } else {
            this.dragUpdate()
          }
        }
      })
    }
  }


  // update () {

  // }

  easeInOutQuad(t) {
    return t * (2 - t)
  }
}