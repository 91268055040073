import Select from '../Select'
import Footer from '@/components/Footer'
import Logo from '@/assets/images/Logo_Jeu_de_Paume_Trame_Principale.png'
const images = require.context('@/assets/images', false, /\.png$|\.jpg$|\.svg$/)

export default {
  name: 'Home',
  components: {
    Select,
    Footer
  },
  data () {
    return {
      logo: Logo
    }
  },
  methods: {
    loadImg(path) {
      return images('./' + path)
    }
  }
}