
import Blocks from '@/components/Blocks'
import Slider from '@/components/Slider'

const images = require.context('@/assets/images', false, /\.png$|\.jpg$/)
export default {
  name: 'Expl',
  components: {
    Blocks,
    Slider
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    effect: {
      type: String,
      default: ''
    }
  },
  methods: {
    loadImg(path) {
      return images('./' + path)
    },
    close() {
      if(this.$attrs.origin === 'effect') {
        this.$parent.toggleInfos()
      } else {
        this.$router.go(-1)
      }
    }
  }
}