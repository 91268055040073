import Bowser from 'bowser';
import Logo from '@/assets/images/Logo_Jeu_de_Paume_Trame_Principale.png'
const images = require.context('@/assets/images', false, /\.png$|\.jpg$|\.svg$/)

export default {
  name: 'DesktopScreen',
  mounted () {
    this.$nextTick(this.init.bind(this))
  },
  data () {
    return {
      isDesktop: false,
      logo: Logo
    }
  },
  methods: {
    init () {
      this.isDesktop = Bowser.getParser(window.navigator.userAgent).parsedResult.platform.type === 'desktop'
    },
    loadImg(path) {
      return images('./' + path)
    }
  }
}