
<template>
  <div class="SlideSelector">
    <div class="slider-el">
      <slot @click="clicked"></slot>
    </div>
  </div>
</template>

<script>
import './style.less'

import Slide from '@/js/Slide.js'

export default {
  name: 'SlideSelector',
  data () {
    return {
      lastSelected: null,
      currentSelected: null
    }
  },
  methods: {
    initSlide () {
      this.slide = new Slide(this.$el, this.dragEnd)
      // console.log('SLIDE SELECTOR')
    },
    dragEnd (i) {
      this.lastSelected = this.currentSelected
      this.currentSelected = i

      this.$emit('selected-filtre', [this.currentSelected, this.lastSelected])
    },
    clicked() {
      // console.log('CLICKED')
    }
  }
}
</script>
