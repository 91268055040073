/* eslint-disable */
// import component from '@/mixins/component'

export default {
  name: 'TextBlock',
  // mixins: [component],
  props: {
    data: {
      type: Object,
      default: ''
    },
  },
  data () {
    return {
    }
  },
  created () {
  },
  mounted () {
   
  },
  methods: {
  
  }
}
