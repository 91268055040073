const images = require.context('@/assets/images/mentions', false, /.*\.(png|jpg|svg)$/)

export default {
  name: 'Footer',
  data () {
    return {
      langs: ['fr', 'en']
    }
  },
  methods: {
    loadImg (path) {
      return images('./' + path)
    },
    changeLang (lang) {
      this.$i18n.locale = lang
    }
  }
}
