<template>
  <div class="range -hide">
    <div ref="knob" class="knob">
      <div/>
    </div>
  </div>
</template>

<script>
import './style.less'

import gsap from 'gsap'
import Draggable from 'gsap/Draggable'

export default {
  name: 'Range',
  data () {
    return {
      value: undefined,
      showed: false
    }
  },
  props: {
    initValue: {
      type: Number,
      default () {
        return 0
      }
    },
    min: {
      type: Number,
      default () {
        return 0
      }
    },
    max: {
      type: Number,
      default () {
        return 1
      }
    }
  },
  mounted () {
    this.$nextTick(this.init)
  },
  methods: {
    init () {
      if (this.drag) {
        this.reload()
        return
      }

      this.value = this.initValue

      const initPos = 1 - this.value / (this.max - this.min) - this.min
      gsap.set(this.$refs.knob, { yPercent: -50, top: `${initPos * 100}%` });

      this.drag = Draggable.create(this.$refs.knob, {
        type: 'y',
        trigger: this.$el,
        bounds: this.$el,
        onPress: () => {
          this.setValue()
          this.$el.classList.add('-active')
        },
        onDrag: this.setValue,
        onDragEnd: () => {
          this.$el.classList.remove('-active')
        }
      });
    },
    setValue (ev, y = this.drag[0].y, minY = this.drag[0].minY, maxY = this.drag[0].maxY ) {
      const value = ( (1 - (y - minY) / (maxY - minY)) ) * (this.max - this.min) + this.min
      this.value = value

      this.updatedRange()
    },
    show () {
      this.showed = true
      this.$el.classList.remove('-hide')
    },
    hide () {
      this.$el.classList.add('-hide')
    },
    updatedRange (value = this.value) {
      this.$emit('get-value', value)
    },
    getRange () {
      return this.value
    },

    reload () {
      gsap.set(this.$refs.knob, { y: this.drag[0].maxY });
    }
  }
}
</script>
