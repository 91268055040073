/* eslint-disable */
import SliderElement from '@/js/sliderElement'
import gsap, {Power2} from 'gsap'
import Bowser from 'bowser'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
const images = require.context('@/assets/images', false, /\.png$|\.jpg$/)

export default {
  name: 'Slider',
  components: {
  },
  props: {
    data: {
      type: Array,
      default: [],
      currentIndex: 0
    },
    link: {
      type: String,
      default: ''
    }
  },
  mounted () {
    let observer = new IntersectionObserver(this.watchItems.bind(this), {
      threshold: 1

    })
    this.$refs.image.forEach(el => {
      observer.observe(el)
    })

    this.$refs.image.forEach((el, i) => {
      el.index = i
    })

    this.$refs.prev.addEventListener('click', () => {
      if (this.currentIndex - 1 > -1) {
        this.goTo(this.currentIndex - 1)
        if (this.currentIndex === 0) {
          this.$refs.prev.classList.add('disabled')
          this.$refs.prev.classList.remove('disabled')
        } else {
          this.$refs.prev.classList.remove('disabled')
          this.$refs.prev.classList.remove('disabled')
        }
      }
    })
    this.$refs.next.addEventListener('click', () => {
      if (this.currentIndex + 1 < this.$refs.image.length) {
        this.goTo(this.currentIndex + 1)
        if (this.currentIndex + 1 === this.$refs.image.length) {
          this.$refs.prev.classList.add('disabled')
          this.$refs.prev.classList.remove('disabled')
        } else {
          this.$refs.prev.classList.remove('disabled')
          this.$refs.prev.classList.remove('disabled')
        }
      }
    })
  },
  methods: {
    loadImg(path) {
      return images('./' + path)
    },
    watchItems (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          this.currentIndex = entry.target.index
          this.$refs.prev.classList.remove('disabled')
          this.$refs.next.classList.remove('disabled')
          if (this.currentIndex === 0) this.$refs.prev.classList.add('disabled')
          if (this.currentIndex === this.$refs.image.length - 1) this.$refs.next.classList.add('disabled')
        }
      }
    },
  
    goTo (id) {
      this.$refs.slider.classList.remove('snap')
      
      gsap.to(this.$refs.slider, {scrollTo: {x: this.$refs.image[id]}, duration: 0.5, ease: Power2.easeOut, onComplete: () => { setTimeout(() => {this.$refs.slider.classList.add('snap')}, 200) }})
      this.currentIndex = id
    }
  }
}
