import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
Vue.use(VueMeta)
Vue.config.productionTip = false

// Ready translated locale messages
import fr from '@/assets/content/fr.json'
import en from '@/assets/content/en.json'
const messages = {
  en,
  fr
}

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: /^fr\b/.test(navigator.language) ? 'fr' : 'en', // set locale
  messages, // set locale messages
})


new Vue({
  router,
  i18n,
  render: h => h(App),
  metaInfo: () => ({
    title: 'Expo MoMA au Jeu de Paume',
    titleTemplate: '%s', // '%s | Vue Meta Examples'
    htmlAttrs: {
      lang: 'en',
      amp: undefined
    },
    headAttrs: {
      test: true
    },
    bodyAttrs: {
      tabIndex: 0
    },
    meta: [
      { name: 'description', content: 'Hello', vmid: 'test' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no'}
    ],
    script: [
      { innerHTML: '{ "@context": "http://www.schema.org", "@type": "Organization" }', type: 'application/ld+json' },
      { innerHTML: '{ "body": "yes" }', body: true, type: 'application/ld+json' }
    ],
    __dangerouslyDisableSanitizers: ['script']
  })
}).$mount('#app')
