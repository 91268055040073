import {
  Renderer,
  Geometry,
  Plane,
  Texture,
  Program,
  Mesh,
  Vec2,
  Vec3,
  Color,
  Transform,
  RenderTarget,
  Triangle,
  Camera
} from 'ogl';
import * as dat from 'dat.gui';
import {
  vec2
} from 'gl-matrix'


import CameraControls from '@/components/CameraControls'
import SlideSelector from '@/components/SlideSelector'
import PopTexts from '@/components/PopTexts'
import EndScreen from '@/components/EndScreen'
import Title from '@/components/Title'

import Raf from '@/js/raf'
import Mouse from '@/js/mouse'

import vertex from 'raw-loader!glslify-loader!../../glsl/double-exposure/double.vert'
import fragment from 'raw-loader!glslify-loader!../../glsl/double-exposure/double.frag'

import vertexPost from 'raw-loader!glslify-loader!../../glsl/double-exposure/doublePost.vert'
import fragmentPost from 'raw-loader!glslify-loader!../../glsl/double-exposure/doublePost.frag'

import vertexLogo from 'raw-loader!glslify-loader!../../glsl/logo/logo.vert'
import fragmentLogo from 'raw-loader!glslify-loader!../../glsl/logo/logo.frag'

const _VEC2 = vec2.create()

export default {
  name: 'Double-exposure',
  components: {
    CameraControls,
    SlideSelector,
    PopTexts,
    EndScreen,
    Title
  },
  data() {
    return {
      params: {},
      isPlaying: true,
      hasStream: false,
      imgs: [],
      planes: [],
      currentPlane: null,
      cameraMessage: 1,
      isFinished: false,
      canBackAction: false,
      seeBin: false,
      isRedoFirst: false,
      ratio: 0.75
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initCanvas()
      this.$refs.selector.initSlide()
    })
  },
  destroyed() {
    Mouse.off('down')
    Mouse.off('drag')
    Mouse.off('pinch')
    Raf.unsuscribe('doubleExposure')
  },
  methods: {
    initCanvas() {
      this.createRenderer()
      this.createPlanes()

      this.initEvents()

      Raf.suscribe('doubleExposure', () => {
        this.update()
      })

      Mouse.on('down', () => {
        if (this.currentPlane === null || !Mouse.currentEvent.target.classList.contains('render-canvas')) return
        this.mouseOrigin = vec2.fromValues(Mouse.cursor[0], Mouse.cursor[1])
        this.planeOrigin = vec2.fromValues(-this.planes[this.currentPlane].position[0], -this.planes[this.currentPlane].position[1])
      })
      Mouse.on('drag', () => {
        // console.log(this.imgs.lezngrth)
        console.log('drag')

        if (this.currentPlane === null || !Mouse.currentEvent.target.classList.contains('render-canvas')) return
        this.planeMove(this.currentPlane)
      })
      Mouse.on('pinch', (way) => {
        // console.log(this.imgs.lezngrth)
        // console.log(way)
        // if (this.currentPlane === null || !Mouse.currentEvent.target.classList.contains('render-canvas') || this.imgs.length <= 1) return
        this.planeZoom(this.currentPlane, way)
      })

    },
    createRenderer() {
      this.renderer = new Renderer({
        dpr:2,
        width:  window.innerHeight * this.ratio,
        height: window.innerHeight,
        preserveDrawingBuffer: true
      });

      console.log(this.ratio)

      this.gl = this.renderer.gl;
      // this.renderer.preserveDrawingBuffer = true
      
      this.gl.canvas.classList.add('render-canvas')
      this.$el.appendChild(this.gl.canvas);
      this.scene = new Transform();
      this.camera = new Camera(this.gl, {
        fov: 15
      });
      this.camera.position.z = 3.5;

      this.setPost()

    },
    play() {
      this.video.play()
      this.isPlaying = true
      this.texture.image = this.imgs[0]
      this.texture.needsUpdate = true;

      this.onResize()

    },

    createPlanes() {
      // Triangle that covers viewport, with UVs that still span 0 > 1 across viewport
      for (let index = 0; index < 2; index++) {
        let geometry = new Plane(this.gl, {
          position: {
            size: 2,
            data: new Float32Array([-1, -1, 3, -1, -1, 3])
          },
          uv: {
            size: 2,
            data: new Float32Array([0, 0, 2, 0, 0, 2])
          },
        });

        let texture = new Texture(this.gl, {
          generateMipmaps: false
        })


        // this.texture1 = new Texture(this.gl, {

        // })
        // this.texture2 = new Texture(this.gl, {
        //   generateMipmaps: false
        // })

        this.ratio = getComputedStyle(document.querySelector('body')).getPropertyValue("--ma-variable")

        let program = new Program(this.gl, {
          vertex,
          fragment,
          uniforms: {
            tMap1: {
              value: texture
            },

            u_mapResolution: {
              value: new Vec2(0, 0)
            },
            u_resolution: {
              value: new Vec2(window.innerWidth, window.innerHeight)
            },
            u_aspect: {
              value: new Vec2(1, 1)
            }
          },
          cullFace: null
        });

        let mesh = new Mesh(this.gl, {
          geometry,
          program
        });
        mesh.program.uniforms.u_mapResolution.value = new Vec2(window.innerHeight * this.ratio, window.innerHeight)
        mesh.texture = texture
        mesh.position.set(0, 0, 0)
        mesh.scaleVal = 1
        mesh.scale.set(1, 1, 1)
        this.planes[index] = mesh
      }

    },
    setPost() {
      this.target = new RenderTarget(this.gl, {
        width: 512,
        height: 512,
      });
      this.target1 = new RenderTarget(this.gl, {
        width: 512,
        height: 512,
      });

      const geometry = new Triangle(this.gl);

      // Create 10 random lights

      let texture = new Texture(this.gl, {
        generateMipmaps: false
      })

      const program = new Program(this.gl, {
        vertex: vertexPost,
        fragment: fragmentPost,
        uniforms: {
          tMap: {
            value: this.target.textures[0]
          },
          tMap1: {
            value: this.target1.textures[0]
          },

          uAspect: {
            value: 1
          },
          uTime: {
            value: 0
          },
        },
      });

      this.post = new Mesh(this.gl, {
        geometry,
        program
      });
      this.post.texture = texture
      this.post.setParent(this.scene);

    },

    initEvents() {
      window.addEventListener('resize', this.onResize)
    },

    onVideoLoad() {
      this.planes.forEach(el => {
        el.program.uniforms.u_mapResolution.value = new Vec2(1080, 1920)
      })
      this.$refs.PopTexts.showText(0)
      this.$refs.selector.$el.querySelectorAll('.selector__item')[0].classList.remove('hidden')
    },

    onResize() {
      const w = window.innerHeight * 0.75
      const h = window.innerHeight

      this.renderer.setSize(w, h)

      // Resolution
      // this.mesh.program.uniforms.u_resolution.value = new Vec2(w, h)
    },
    update() {
      if (this.isRedoFirst) {
        this.planes[0].texture.image = this.video
        this.planes[0].texture.needsUpdate = true;
      } else if (this.hasStream && this.isPlaying) {
        // console.log(this.imgs.length)
        if (this.imgs.length === 0) {
          this.planes[0].texture.image = this.video
          this.planes[0].texture.needsUpdate = true;
        } else if (this.imgs.length === 1) {
          this.planes[1].texture.image = this.video
          this.planes[1].texture.needsUpdate = true;
        }
      }
      
      this.renderer.render({
        scene: this.planes[0],
        camera: this.camera,
        target: this.target
      });
      this.renderer.render({
        scene: this.planes[1],
        camera: this.camera,
        target: this.target1
      });


      this.post.program.uniforms.uAspect.value = this.renderer.width / this.renderer.height;
      this.post.program.uniforms.uTime.value = 0.001;

      this.renderer.render({
        scene: this.scene
      });
    },

    updateImg(img) {
      if (this.isRedoFirst) {
        this.imgs[0] = img
        this.imgs.pop()
        this.$refs.selector.$el.querySelectorAll('.selector__item')[0].style.backgroundImage = `url(${this.imgs[0].src}`
        this.planes[0].texture.image = this.imgs[0]
        this.planes[0].texture.image.needsUpdate = true

        this.seeBin = true
        this.cameraMessage = 2
        this.isRedoFirst = false
        this.$refs.cameraControls.isEnd = true
        return
      }

      this.$refs.selector.$el.querySelectorAll('.selector__item')[this.imgs.length - 1].style.backgroundImage = `url(${this.imgs[this.imgs.length - 1].src}`
      this.planes[this.imgs.length - 1].texture.image = this.imgs[this.imgs.length - 1]
      this.planes[this.imgs.length - 1].texture.image.needsUpdate = true;

      if (this.imgs.length === 1) {
        this.$refs.PopTexts.showText(1)
        this.$refs.selector.slide.goTo(1)
        this.cameraMessage = 2
        this.$refs.selector.$el.querySelectorAll('.selector__item')[1].classList.remove('hidden')
      } else {
        this.seeBin = true
        this.$refs.PopTexts.showText(2)
        this.cameraMessage = 'OK'
        this.$refs.cameraControls.isEnd = true
      }
    },

    flipPlane(way) {
      console.log(way)
      setTimeout(() => {
        // console.log(way)
        let plane = this.planes[this.imgs.length]
        this.planes.forEach((el, i) => {
          if(i >= this.imgs.length){
            way === "user" ? el.orientation = 1 : el.orientation = -1
            el.scale.set(el.scaleVal  * el.orientation, el.scaleVal, 1)
          }
        })
       
      }, 200)
      
    },

    selectedFiltre(valueArr) {
      this.currentPlane = valueArr[0]
    },

    planeMove(id) {
      let translate = vec2.sub(_VEC2, this.mouseOrigin, Mouse.cursor)
      translate = vec2.sub(_VEC2, translate, this.planeOrigin)
      translate = vec2.scale(_VEC2, translate, 0.5)
      this.planes[id].position.set(translate[0], translate[1], 0)
    },
    planeZoom(id, way) {
      // console.log(id, way, this.planes[id].scaleVal , this.planes[id].orientation)
      if(way === 'out' && this.planes[id].scaleVal < 0.2 ) return
      way === 'in' ? this.planes[id].scaleVal += 0.05 : this.planes[id].scaleVal -= 0.05
      this.planes[id].scale.set(this.planes[id].scaleVal * this.planes[id].orientation, this.planes[id].scaleVal , this.planes[id].scaleVal)
    },

    //
    // BackAction
    //
    setBackAction (action = true) {
      this.canBackAction = action
    },
    backAction () {
      // remove last img
      this.imgs.pop()
      this.isRedoFirst = false

      // 
      if (this.imgs.length === 0) {
        this.canBackAction = false

        this.planes[0].texture.image = this.video
        this.planes[1].texture.image = ''
        this.$refs.selector.$el.querySelectorAll('.selector__item')[0].style.backgroundImage = ''
        this.$refs.selector.slide.goTo(0, 0)

        this.cameraMessage = 1
      } else {
        this.canBackAction = true

        this.planes[1].texture.image = this.video
        this.$refs.selector.$el.querySelectorAll('.selector__item')[1].style.backgroundImage = ''
        this.$refs.selector.slide.goTo(1, 0)

        this.isFinished = false
        this.isPlaying = true
        this.hasStream = true
        this.cameraMessage = 2
        this.$refs.cameraControls.isEnd = false
      }

    },

    //
    // Finish
    //
    finish() {

      this.isFinished = true
      this.$nextTick(() => {
        this.$refs.endScreen.setFinal()
      })
      Raf.unsuscribe('doubleExposure')
      this.$refs.PopTexts.hideText()
      this.$el.querySelector('.render-canvas').classList.add('finished')
    },

    reload () {
      Raf.suscribe('doubleExposure', this.update)
      this.$refs.cameraControls.isEnd = false
      this.isPlaying = true
      this.hasStream = true
      this.cameraMessage = 1
      this.imgs = []
      this.isFinished = false 
      this.planes[0].texture.image = this.video
      this.planes[1].texture.image = '';
      this.planes.forEach(el => {
        el.position.set(0, 0, 0)
        el.scaleVal = 1
        el.scale.set(1, 1, 1)
      })
      this.$refs.selector.$el.querySelectorAll('.selector__item')[1].classList.add('hidden')
      this.$refs.selector.slide.goTo(0, 0)
      this.$refs.selector.$el.querySelectorAll('.selector__item').forEach(el => {
        el.style.backgroundImage = ''
      })
      this.$el.querySelector('.render-canvas').classList.remove('finished')
    },

    removePicture () {
      const slideIndex = this.$refs.selector.currentSelected
      console.log(slideIndex)

      this.canBackAction = false
      this.seeBin = false

      if (slideIndex === 0) {
        console.log('Remove : premier')
        this.planes[0].texture.image = this.video

        this.$refs.selector.$el.querySelectorAll('.selector__item')[0].style.backgroundImage = ''
        this.isPlaying = true
        this.hasStream = true
        this.cameraMessage = 1
        this.isRedoFirst = true
        this.$refs.cameraControls.isEnd = false
      } else {
        this.backAction()
      }
    }
  }
}